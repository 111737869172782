import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Rview from "@/components/routerView/index.vue";

Vue.use(VueRouter);

export const routes: Array<RouteConfig> = [
  {
    path: "/login",
    meta: {
      title: "登录",
      without_auth: true,
    },
    component: () => import("@/views/login/index.vue"),
  },
];

export const asyncRoutes = [
  {
    path: "/",
    redirect: "/user",
    component: () => import("@/components/global/index.vue"),
    meta: {
      roles: [1],
    },
    children: [

      {
        path: "order",
        name: "order",
        meta: {
          title: "订单管理",
          cache: false,
          roles: [1],
        },
        redirect: "/order/",
        component: Rview,
        children: [
          {
            path: "",
            meta: {
              title: "订单查询",
              cache: false,
              roles: [1],
              // without_auth: true
            },
            component: () => import("@/views/order/index.vue"),
          },
          {
            path: "edit",
            meta: {
              title: "订单详情",
              titleEdit:'订单详情',
              cache: false,
              roles: [1],
              // without_auth: true
            },
            component: () => import("@/views/order/edit/index.vue"),
          },
        ],
      },
      {
        path: "user",
        name: "user",
        meta: {
          title: "用户管理",
          cache: false,
          roles: [1],
        },
        redirect: "/user/",
        component: Rview,
        children: [
          {
            path: "",
            meta: {
              title: "用户列表",
              cache: false,
              roles: [1],
              // without_auth: true
            },
            component: () => import("@/views/user/index.vue"),
          },
          {
            path: "edit",
            meta: {
              title: "添加用户",
              titleEdit:'编辑用户',
              cache: false,
              roles: [1],
              // without_auth: true
            },
            component: () => import("@/views/user/edit/index.vue"),
          }
        ],
      },
      {
        path: "auth",
        meta: {
          title: "账号管理",
          cache: false,
          roles: [1],
          // without_auth: true
        },
        redirect: "/auth/admin",
        component: Rview,
        children: [
          {
            path: "admin",
            meta: {
              title: "",
              cache: false,
              roles: [1],
              // without_auth: true
            },
            component: Rview,
            children: [
              {
                path: "",
                meta: {
                  title: "员工管理",
                  cache: false,
                  roles: [1],
                  // without_auth: true
                },
                component: () => import("@/views/auth/admin/index.vue"),
              },
              {
                path: "edit",
                meta: {
                  title: "添加管理员",
                  titleEdit: "编辑管理员",
                  cache: false,
                  roles: [1],
                  // without_auth: true
                },
                component: () => import("@/views/auth/admin/edit/index.vue"),
              },
              {
                path: "",
                meta: {
                  title: "角色管理",
                  cache: false,
                  roles: [1],
                  // without_auth: true
                },
                component: () => import("@/views/auth/role/index.vue"),
              },
              {
                path: "edit",
                meta: {
                  title: "添加角色",
                  titleEdit: "编辑角色",
                  cache: false,
                  roles: [1],
                  // without_auth: true
                },
                component: () => import("@/views/auth/role/edit/index.vue"),
              },
            ],
          },
          {
            path: "role",
            meta: {
              title: "角色管理",
              cache: false,
              roles: [1],
              // without_auth: true
            },
            component: Rview,
            children: [
              {
                path: "",
                meta: {
                  title: "角色列表",
                  cache: false,
                  roles: [1],
                  // without_auth: true
                },
                component: () => import("@/views/auth/role/index.vue"),
              },
              {
                path: "edit",
                meta: {
                  title: "添加角色",
                  titleEdit: "编辑角色",
                  cache: false,
                  roles: [1],
                  // without_auth: true
                },
                component: () => import("@/views/auth/role/edit/index.vue"),
              },
            ],
          },
        ],
      },
     
     
      {
        path: "warehouse",
        name: "warehouse",
        meta: {
          title: "",
          cache: false,
          roles: [1],
        },
        redirect: "/warehouse/",
        component: Rview,
        children: [
          {
            path: "out",
            meta: {
              title: "出库管理",
              cache: false,
              roles: [1],
              // without_auth: true
            },
            component: () => import("@/views/warehouse/out/index.vue"),
          },
          {
            path: "put",
            meta: {
              title: "入库管理",
              cache: false,
              roles: [1],
              // without_auth: true
            },
            component: () => import("@/views/warehouse/put/index.vue"),
          },
          {
            path: "reach",
            meta: {
              title: "到店管理",
              cache: false,
              roles: [1],
              // without_auth: true
            },
            component: () => import("@/views/warehouse/reach/index.vue"),
          },  
          
        ],
      },
     
      {
        path: "meal_record",
        name: "meal_record",
        meta: {
          title: "充值管理",
          cache: false,
          roles: [1],
        },
        redirect: "/meal_record/",
        component: Rview,
        children: [
          {
            path: "",
            meta: {
              title: "充值管理",
              cache: false,
              roles: [1],
              // without_auth: true
            },
            component: () => import("@/views/meal_record/index.vue"),
          },
        ],
      },
      {
        path: "goods",
        name: "goods",
        meta: {
          title: "下单管理",
          cache: false,
          roles: [1],
        },
        redirect: "/goods/",
        component: Rview,
        children: [
          {
            path: "",
            meta: {
              title: "商品列表",
              cache: false,
              roles: [1],
              // without_auth: true
            },
            component: () => import("@/views/goods/index.vue"),
          },
         
        ],
      },
      
    ],
  },
];

export const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
