function hasPermission(roles:any, route:any) {
    if (route.meta && route.meta.roles) {
      return roles.some((role:any) => route.meta.roles.includes(role))
    } else {
      return false
    }
  }
  // 递归过滤异步路由表，筛选角色权限路由
  export function filterAsyncRoutes(routes:any, roles:any) {
    const res:any = [];
    routes.forEach((route:any) => {
      const tmp = { ...route }
      if (hasPermission(roles, tmp)) {
        if (tmp.children) {
          tmp.children = filterAsyncRoutes(tmp.children, roles)
        }
        res.push(tmp)
      }
    })
  
    return res
  }